import { mixin } from "@/mixins/mixin";

export default {
  allSlips: (state) => {
    return state.slips
  },

  currentSlipData: (state) => {
    return state.currentSlip
  },


}
