import {
  FETCH_USERS,
  ADD_USERS,
  UPDATE_USERS,
  REMOVE_USERS,
} from './mutation-types'

import Vue  from 'vue'

export default {
  [FETCH_USERS](state, data) {
    state.users = data
  },
  [ADD_USERS](state, user) {
    let usersList = [...state.users.list]
    usersList.unshift(user)
    state.users.list = usersList
  },
  [UPDATE_USERS](state, user) {
    const index = state.users.list.findIndex((el)=>el.id === user.id)
    Vue.set(state.users.list, index, user)
  },
  [REMOVE_USERS](state, id) {
    const index = state.users.list.findIndex((el)=>el.id === id)
    state.users.list.splice(index, 1);
  },
}
